import React from 'react'

const Button = ({color,text,border,textcolor}:{color?:string,text:string,border?:string,textcolor?:string}) => {
  return (
    <button type='submit' className={`${color}  ${textcolor && textcolor} text-center w-fit mx-auto px-3 py-1 rounded-md cursor-pointer ${border}`}>
     {text} 
    </button>
  )
}

export default Button