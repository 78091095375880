import React from 'react'
import TopHeader from '../components/TopHeading'

const News = () => {
  return (

    <div className='w-10/12 md:w-8/12 lg:w-9/12 mx-auto mt-20 mb-16'> 
      <TopHeader text="News"/>
      <h1 className='text-xl flex justify-center mt-6'>🔜 Coming soon...</h1>
      </div>
  )
}

export default News