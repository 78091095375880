export type responseprops = {
        id: number
        response: string
        isChecked: boolean
}

export type quizDataProps = {
    id: number
    question: string
    responses: responseprops []  
}


export const quizData : quizDataProps []= [
    {
        id:1,
        question:"Que fais tu comme profession?",
        responses:[
            {
                id:1,
                response:"Consultant",
                isChecked:false
            },
            {
                id:2,
                response:"Pompier",
                isChecked:false
            },
            {
                id:3,
                response:"Commerçant",
                isChecked:false
            },
            {
                id:4,
                response:"Médecin",
                isChecked:false
            },
            
            {
                id:5,
                response:"Avocat",
                isChecked:false
            },
            {
                id:6,
                response:"Instituteur",
                isChecked:false
            }
            ,
            {
                id:7,
                response:"Administrateur",
                isChecked:false
            },
            {
                id:8,
                response:"Autres spécifier",
                isChecked:false
            }
            ,
            {
                id:9,
                response:"Chômeur",
                isChecked:false
            }]
    },
    {
        id:2,
        question:"Comment peux -tu décrire ta relation avec ton conjoint/te?",
        responses:[
            {
                id:1,
                response:"Paisible",
                isChecked:false
            },
            {
                id:2,
                response:"Orageuse",
                isChecked:false
            },
            {
                id:3,
                response:"Sèche",
                isChecked:false
            },
            {
                id:4,
                response:"monotone",
                isChecked:false
            },
            
            {
                id:5,
                response:"En voie de séparation",
                isChecked:false
            },
            {
                id:6,
                response:"séparer",
                isChecked:false
            }
             
         ]
    },
    {
        id:3,
        question:"Combien de fois avez vous des rapports sexuels par semaine?",
        responses:[
            {
                id:1,
                response:"5 fois",
                isChecked:false
            },
            {
                id:2,
                response:"3 fois",
                isChecked:false
            },
            {
                id:3,
                response:"2 fois",
                isChecked:false
            },
            {
                id:4,
                response:"1 fois",
                isChecked:false
            },
            
            {
                id:5,
                response:"presque pas",
                isChecked:false
            } 
             
         ]
    },
    {
        id:4,
        question:"Quelles sont les habitudes avec lesquelles tu te bats vraiment et qui détruisent ton couple?",
        responses:[
            {
                id:1,
                response:"querelles",
                isChecked:false
            },
            {
                id:2,
                response:"mensonge",
                isChecked:false
            },
            {
                id:3,
                response:"colère",
                isChecked:false
            },
            {
                id:4,
                response:"masturbation",
                isChecked:false
            },
            
            {
                id:5,
                response:"adultère",
                isChecked:false
            } 
            ,
            {
                id:6,
                response:"Dépenses facilement sans calculer",
                isChecked:false
            },
            
            {
                id:7,
                response:"Sortie nocturnes",
                isChecked:false
            } 
            ,
            {
                id:8,
                response:"pornographie",
                isChecked:false
            },
            
            {
                id:9,
                response:"Alcool",
                isChecked:false
            } 
            ,
            
            {
                id:10,
                response:"Autres",
                isChecked:false
            } 
             
         ]
    }
    ,
    {
        id:5,
        question:"Qu’est ce que tu aimerais qui change dans ton couple?",
        responses:[
            {
                id:1,
                response:"Plus de sexe",
                isChecked:false
            },
            {
                id:2,
                response:"Meilleure communication",
                isChecked:false
            },
            {
                id:3,
                response:"Qu’on ait un plus d’argent",
                isChecked:false
            },
            {
                id:4,
                response:"Passe plus de temps ensemble",
                isChecked:false
            },
            
            {
                id:5,
                response:"recevoir plus d’attention de mon conjoint/te",
                isChecked:false
            } 
            ,
            
            {
                id:6,
                response:"recevoir plus d’honneur de mon conjoint/te",
                isChecked:false
            } 
            ,
            
            {
                id:7,
                response:"Autres",
                isChecked:false
            } 
             
         ]
    },
]

export type afterClassuizProps = {
    question:string
    id:number
}
export const afterClassuiz : afterClassuizProps[]= [
    {
        id:1,
        question:"Qu’as tu appris durant ces cours?"
    },
    {
        id:2,
        question:"Que vas tu appliquer à partir d’aujourd’hui?"
    }
    ,
    {
        id:3,
        question:"Qu’est ce qui a changé en toi maintenant?"
    },
    {
        id:4,
        question:"Qu’est ce qui t’a le plus marqué dans cette classe?"
    },
    {
        id:5,
        question:"Vas- tu recommander ces cours à un autre couple?"
    },
    {
        id:6,
        question:"Qu’est ce que tu aimerais que l’ on ajoute à ces cours?"
    },

]
export type ConflitsProps  = {   
     isChecked:boolean
     question:string
     id:number
    }
export const conFlitQuestion : ConflitsProps[] = [
    {
        id:1,
        question:"Communication",
        isChecked:false
    },
    {
        id:2,
        question:"Sexe",
        isChecked:false
    }
    ,
    {
        id:3,
        question:"Finances",
        isChecked:false
    },
    {
        id:4,
        question:"Vie sociale",
        isChecked:false
    },
    {
        id:5,
        question:"Belle famille",
        isChecked:false
    },
    {
        id:6,
        question:"Violence conjugale ",
        isChecked:false
    },
    {
        id:7,
        question:"Insécurité dans le couple",
        isChecked:false
    },
    {
        id:8,
        question:"Infidélité ",
        isChecked:false
    },
    {
        id:9,
        question:"Religion",
        isChecked:false
    },
    {
        id:10,
        question:"Autres  ",
        isChecked:false
    },

]