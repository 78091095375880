import React ,{ useState} from 'react'
import { toast } from 'react-hot-toast';
import { db} from "../Firebase"
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { useContextApi } from '../Context/Context';
 




const Signup = () => {

      const [FormData,setFormData] = useState('')
      const [isLoading, setisLoading] = useState(false)
      const [Error, setError] = useState("")
      const {setIsFormVisible, setGetSavedPassword} = useContextApi()
      

       
   
  
      
     
     
     
 
      const envoyerCode = async (e:React.FormEvent) =>{
                    e.preventDefault()
                    setError("")

          try {

            setisLoading(true)
            const q = query(collection(db, 'users'), where('password', '==', FormData));
        
            const querySnapshot = await getDocs(q);
            if(querySnapshot.empty){
              toast.error("Le code est incorrect.")
               
           
            }else{

              const userDoc = querySnapshot.docs[0];
              await updateDoc(doc(db, 'users', userDoc.id), {
                verified: true
              });

              if(typeof window !== 'undefined'){
              localStorage.setItem('userID',userDoc.id)
              setGetSavedPassword(userDoc.id)
              setIsFormVisible(false)
              setFormData("")
             
              }
             
            
            toast.success("Bravo 👏👏")
            
            }
          } catch (error: any) {
            
              setError(error)
          }

          finally{
            setisLoading(false)
          }
     
            
      }

    

  

  return (

  <div>
       <form onSubmit={envoyerCode} className='mt-6'>


      {Error && <div className='w-full px-2'><h1 className='text-center font-semibold text-red-500'>{Error}</h1></div>}
    
     
    <div className='flex flex-col items-center justify-center space-y-3'>
    <p className='text-xl'>Entrer le code:</p>
    <input
     value={FormData}
     onChange={(e)=>setFormData(e.target.value)}
     className='w-full border border-gray-300 
     px-3 py-1 rounded-md outline-none'
     />
    </div>

     <section className='flex justify-center mt-4'>
      {isLoading ? <h1 className='text-xl font-semibold text-red-500'>Loading ...</h1> 
      :
      (

        <button 
        disabled={!FormData}
        className={`${FormData ? "bg-blue-600" : "bg-gray-200"} rounded-md  px-3 py-1 text-white`}>
          Envoyer
        </button>
        
     
      )
    }
    </section>

    

    </form>
  </div>
    
  )
}

export default Signup