import { Link } from 'react-router-dom'
import { BiDonateHeart,  } from '../Icons'
import Button from './Button'
import { motion } from "framer-motion"
 

const TopHeader = () => {

  

  return (
    <nav className='w-full flex justify-between p-3 bg-white overflow-hidden'>

              {/*Letf slide*/}


             <Link to={`/`}>
             <motion.div 
              initial={{
              x:-100,
              opacity:0,
              scale:0.5,
          
             }}
             animate={{
              x:0,
              opacity:1,
              scale:1
             }}
             transition={{
              duration:1.5
             }}
             className='flex items-center space-x-3 cursor-pointer'>
               <img src='/logo1.png' style={{objectFit:'fill', height:60, width:60}} alt='Logo'/>
              <h1 className='font-serif'>École de mariage divin</h1>
              </motion.div>
              </Link>
            
         
         
         {/*Right slide*/}
          <motion.div 
          initial={{
          x:100,
          opacity:0,
          scale:0.5,
       
         }}
         animate={{
          x:0,
          opacity:1,
          scale:1
         }}
         transition={{
          duration:1.5
         }}
     
        className='flex items-center space-x-4'>

        <nav className='flex items-center space-x-3 px-4'>
        <Link to={`/don`}>
        <nav className='flex justify-center items-center space-x-3 '
        >
         <BiDonateHeart className='Icon text-yellow-300 cursor-pointer'/>
          <Button border='hidden md:flex border border-yellow-300 rounded-full hover:bg-yellow-300' text='Faire un don'/>
        </nav>
        </Link>


                    {/*Connect button*/}
{/* 
                    {
                      !IsuserSignedIn ? 
                      (
                        <nav className='hidden lg:flex' onClick={()=>dispatch(Showform(!IsFormVisible))}>
                        <Button border='border border-green-500 rounded-full hover:bg-green-300' text='Se connecter'/>
                        </nav>
                      )
                      :
                      <p  className='hidden lg:flex'>Connecté...</p>
                    } */}
              
           
              {/* <nav className=' hidden lg:flex flex flex-col justify-center items-center cursor-pointer'>
              <MdLanguage className='Icon text-blue-900'/>
              <h1 className='text-[10px]'>{navigator?.language}</h1>
              </nav> */}

                </nav>
      </motion.div>
       
    </nav>
  )
} 

export default TopHeader