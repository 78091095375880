import { configureStore } from '@reduxjs/toolkit'
import MenuSlice from "../features/Menuslice"
import ErrorSlice from '../features/ErrorSlice'
import userSlice from "../features/UserSlice"

export const store = configureStore({
  reducer: {
   
    menu:MenuSlice,
    Error:ErrorSlice,
    userslice:userSlice
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch