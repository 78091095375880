import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface LogState {
  IsVisible: boolean
}

const initialState: LogState = {
    IsVisible: false,
}

export const MenuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    showMenu: (state,action:PayloadAction<boolean>) => {
 
      state.IsVisible = action.payload
    },
 
  },
})

// Action creators are generated for each case reducer function
export const { showMenu } = MenuSlice.actions

export default MenuSlice.reducer