import React from 'react'

const Loading = () => {
  return (
    <section className='flex items-center justify-center h-screen flex-col space-y-5'>
    <img src='https://cdn.dribbble.com/users/765253/screenshots/2540865/loader.gif' 
    style={{height:130,width:150,objectFit:'contain'}}/>
 </section>
  )
}

export default Loading