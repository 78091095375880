import { motion } from "framer-motion"
import  "../Style/style.css"
import { useEffect, useRef, useState } from "react"


const Home = () => {

  const sentence = {
    hidden:{
      opacity:1
    },
    visible:{
      opacity:1,
      transition:{
        delay:0.5,
        staggerChildren:0.08
      }
    }
   }
   const letter ={
    hidden:{
      opacity:0, y:50
    },
    visible:{
      opacity:1, y:0
    }
   }

   const Tiltle = "Bienvenue à l’école de préparation de Mariage Divin. "
   const Tiltle2 = "École où la sagesse et l’expérience se rencontrent."

   const elementRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

   useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current!= null) {
        const topPosition = elementRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (topPosition - windowHeight < 0) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
   
  return (
    <div className='w-full'>
        <div className=' flex w-10/12 md:w-9/12 lg:w-10/12 mx-auto my-10 h-40 md:h-64 lg:h-72'>
        <div className='h-screen flex items-center justify-center bg-no-repeat bg-center bg-cover ' style={{backgroundImage: `url(/union.PNG)`,height:'100%', width:'100%', objectFit:'contain', position:'relative'}}>
        {/* Your content here */}
     <div className='bg-black absolute inset-0 opacity-80 flex items-center justify-center '>
     <div className=' flex w-10/12 md:w-8/12 lg:w-9/12 mx-auto  '>
        <motion.h1 variants={sentence} className="    text-center  font-semibold z-20"
            initial="hidden" animate="visible">
  {
    Tiltle.split("").map((char,ind)=>(

      <motion.span key={char+ "-"+ ind}
       variants={letter}
        className={`text-[#ffc637]       font-Alfa text-[22px] md:text-[24px] lg:text-[34px] `}>
             {char.toUpperCase()}
      </motion.span>
  
    ))
  }
       </motion.h1>
       </div>

      </div>
      </div>
      </div>

      
          {/*École où la sagesse et.... Heading*/}
     <section className=" z-10 w-fit mx-auto my-10">
     <h1 className="dynamic flex py-1 
      px-3 text-center text-xl text-blue-900 z-10 uppercase">{Tiltle2}</h1>
     </section>
       
    <section className="mx-auto w-10/12 md:w-7/12 lg:w-6/12 py-8">
    <video controls>
        <source src={`${process.env.PUBLIC_URL}/entree.MP4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </section>

 
      

       <div className='z-20 w-10/12 mx-auto border border-gray-200 rounded-md  bg-gray-100 mt-6 shadow-lg  py-4'>
       <div className='flex overflow-hidden flex-col justify-center items-center space-y-3 
       md:flex md:flex-row md:items-center  md:w-10/12 md:mx-auto md:space-y-0 
       md:justify-between'>

       {/*main Image photo of owner*/}
      <motion.img
       className='flex-shrink-0'
       alt="Author photo"
       transition={{ duration: 1.2,}}
       initial={{ x:-100,opacity:0}}
       animate={{x:0}}
       whileInView={{opacity:1,x:0}}
       style={{ overflowX:'hidden',height:'300px',width:'300px', objectFit:'contain'}} src='/LP.jpg'>
       </motion.img>
              
      <div className=' w-full sm:w-10/12 md:w-7/12 mx-auto flex flex-col space-y-2'>
     <motion.div transition={{ duration: 1.2,}}
     initial={{ x:100,opacity:0}}
     animate={{x:0}}
     whileInView={{opacity:1,x:0}}
     className='w-full'>

        <p className='px-4 text-center md:text-xl text-justify '>
        "L'amour est synonyme d'une amitié qui a pris du feu. L'esprit d'équipe, de partage et de pardon s'y trouve. L'amour exprime la loyauté aux bonnes ainsi qu'aux mauvaises périodes. Il se contente du peu à sa disposition et tient compte des faiblesses humaines."
        </p>
      </motion.div>
           
      <div className='flex justify-end'>
      <motion.div 
       transition={{
       duration: 1.2,
       }}
initial={{ x:100,opacity:0}}
animate={{x:0}}
whileInView={{opacity:1,x:0}}
     className=' w-full sm:w-10/12 md:w-full mx-auto'>

<h1 className=' text-end my-4 font-bold md:text-[14px] lg:text-[16px] px-4'>Maria Esther WENTUM</h1>
    
      </motion.div> 

</div>
    </div>
  


       </div>
         </div>

         <div className="mainy bg-[#212F3C] my-10  py-10  ">
  <h1 className="  w-full font-serif font-bold  " style={{color:'#F1C40F'}}>L’école qui: <div className="roller font-bold text-white text-[18px] sm:text-[24px] md:text-[34px] ">
    <span id="rolltext"><span className=" font-bold">te forme.</span> <br/>
    t'accompagne dans ton <span className="text-[#3498DB]">projet.</span>
    <br/>
    
    te prépare au <span className="text-[#239B56]">mariage.</span><br/>
    </span>
    </div>
    </h1>
    
  </div>


       


         <div className='w-10/12 mx-auto mt-16 mb-10  '>
         <div className='flex  flex-col justify-center items-center space-y-3 
       lg:flex lg:flex-row lg:items-center lg:space-x-2 lg:space-x-5  lg:mx-auto lg:space-y-0 
       lg:justify-between'>

          <div className='w-full sm:12/12 md:w-9/12 lg:w-full mx-auto   flex flex-col space-y-2 justify-center
           items-center'>

<img
src='/two.PNG'
style={{ width:'90%' , objectFit:'contain'}}
alt="Tighten your belt"
/>

<div>
  <h1 className='font-semibold text-blue-800 shadow-md px-3 py-1'>Attaches ta ceinture, on décolle !</h1>
</div>
          </div>
          

<div className={`${isVisible ? 'disappear': ''} flex flex-col space-y-4 w-full sm:10/12 md:w-9/12
 lg:w-full mx-auto justify-center
 `} ref={elementRef}>
<p className='text-justify '><span className='text-4xl text-yellow-400 font-bold'>L</span>es cours dispensés ont été soigneusement choisis après 10 ans d’ expérience et de réflexion poussée pour nous préparer, équiper, former pour ce long voyage parsemer de beaucoup de joie, de peines, d’incompréhensions et de transformation appelé le MARIAGE.</p>
<p className='text-justify '><span className='text-4xl text-purple-400 font-bold'>I</span>l est temps de choisir la connaissance!</p>
<p className='text-justify '><span className='text-4xl text-red-400 font-bold'>C</span>ar nous nous instruisons et formons pour notre métier d’avenir mais très peu de personnes se disciplinent pour être former au mariage. Seuls les sentiments ne suffisent pas!</p>
<p className='text-justify '><span className='text-4xl text-blue-400 font-bold'>D</span>ieu est sur le point de te guérir, t’éclairer et renouveler tes pensées à travers cette école.</p>
<p className='text-justify '><span className='text-4xl text-green-700 font-bold'>M</span>a prière est que chaque phrase, conseils et expériences partagés vont t’emmener dans une nouvelle dimension de sagesse, de paix et de maturité!</p>

</div>
          </div>
         </div>


     


<section className='relative w-full flex flex-col space-y-3 lg:space-y-6 mb-10 '>

     <section className='relative flex w-10/12  md:w-6/12    lg:w-7/12 mx-auto my-5 justify-center '>
          <h1 className='bg-gradient-to-r from-indigo-600 to-pink-500 text-white text-center   px-4 py-2 font-adelia font-semibold text-[18px] md:text-[20px] lg:text-[22px]'>L’école qui réalise ton projet de vie </h1>
          <motion.div 
          role="banner"
              animate={{
                scale: [0.5, 1, 1, 0.5, 1],
                rotate: [0, 0, 180, 180, 0],
                borderRadius: ["0%", "0%", "50%", "50%", "0%"]
              }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                times: [0, 0.2, 0.5, 0.8, 1],
              
              }}
          className='  absolute  inset-x-0 flex w-10/12 md:w-10/12 lg:w-8/12 mx-auto
         top-0'>
         <img
         src='/love.PNG'
         className=''
         style={{
          height:400, width:'100%', objectFit:'contain', marginTop:-20
         }}
         alt="Love"
         />
         </motion.div>
         </section>
    
    
</section>

       
         
    </div>
  )
}

export default Home