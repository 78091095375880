import React, {useState} from 'react'
import TopHeader from '../components/TopHeading'
import {quizData, quizDataProps, responseprops, afterClassuiz, afterClassuizProps, conFlitQuestion, ConflitsProps} from "../QuizData"




type PropsCheckBox = {
    response:string | undefined 
    status:boolean  | undefined
    index:number  | undefined
    checkHandler: () => void
}

const OptionResponse : responseprops[] = [
    {
        id:1,
        response:"Oui",
        isChecked:false
    },
    {
        id:2,
        response:"Non",
        isChecked:false
    },
    {
        id:3,
        response:"Peut être",
        isChecked:false
    },
    {
        id:4,
        response:"Pourquoi?",
        isChecked:false
    },
    
    {
        id:5,
        response:"Ne Touche pas assez de sujets",
        isChecked:false
    },
    {
        id:6,
        response:"Je n’ai rien appris qui m’a motiver",
        isChecked:false
    }
     
 ]

 const classOptionmedium : responseprops[] = [
    {
        id:1,
        response:"Whatsapp appel (vidéo ou audio)",
        isChecked:false
    },
    {
        id:2,
        response:"Skype",
        isChecked:false
    },
    {
        id:3,
        response:"Zoom",
        isChecked:false
    },
    {
        id:4,
        response:"Signal?",
        isChecked:false
    },
    
    {
        id:5,
        response:"Google meet",
        isChecked:false
    }
     
 ]


export const CheckBoxed = ({response, status, index, checkHandler}:PropsCheckBox)=>{
    return (
        <>
        <label >
       <input type="checkbox" name="myCheckbox" checked={status} onChange={checkHandler} className='my-4' /> {response}: 
      </label>
        </>
    )
}

 
 
export const QuestionAfterClass = ( )=>{
    const [textInput, setInput] = useState("")
    const HandleTextareaInput = (e:React.ChangeEvent<HTMLTextAreaElement>)=>{
        setInput(e.target.value);
    }


   
    return (
        <>
    
             
       <textarea value={textInput}  name="textarea" 
        placeholder='Type...'
        onChange={HandleTextareaInput}
         className='my-4 border border-gray-300 px-2 py-2 rounded-md' /> 
 
        </>
    )
}

const Rafraichissement = () => {

 

    const [quiz, setquiz] = useState<quizDataProps[]>(quizData)
    
    const [conflits, setconflits] = useState<ConflitsProps []>(conFlitQuestion)
    const [classOption, setclassOption] = useState<responseprops []>(classOptionmedium)
    const [Option, setOption] = useState<responseprops[]>(OptionResponse)
    const updateCheckStatus = (questionID:number, responseID:number)=>{
        setquiz(prev=>prev.map((quiz:quizDataProps)=>{
        
            if(quiz.id===questionID){
              const NewArr=  quiz.responses.map((response:responseprops)=>{
                   if(response.id === responseID){
               return  { ...response, isChecked: !response.isChecked }
                   }
                   else {
                    return response
                   }
                })

                return {...quiz, responses: NewArr}
            }
          return quiz
  
        }))
    }


  

    const updateCheck = (index:number) => {
        setOption(prev=>prev.map((option:responseprops) =>
          option.id === index
            ? { ...option, isChecked: !option.isChecked }
            : option
        )
      )
    }

    const updateCheckConflits = (index:number) => {
        setconflits(prev=>prev.map((option:ConflitsProps) =>
          option.id === index
            ? { ...option, isChecked: !option.isChecked }
            : option
        )
      )
    }

    const ClassOptionCheck = (index:number) => {
        setclassOption(prev=>prev.map((option:responseprops) =>
        option.id === index
          ? { ...option, isChecked: !option.isChecked }
          : option
      )
    )
    }
    

  return (
    <div className='w-10/12 md:w-8/12 lg:w-9/12 mx-auto mt-20 mb-16'> 
    <TopHeader text="Cours de raffraichissement pour les couples. "/>
    <h1 className='text-xl flex justify-center mt-6 font-semibold'>Comment briser la monotonie dans le couple?</h1>
    <div className='w-full md:w-11/12 lg:w-9/12 mx-auto'>
<div className='flex flex-col space-y-5  '>

<p className='text-justify mt-10  '>-Appel vidéo zoom 2h/semaine.</p>
<p className='text-justify mt-4 flex  '>-1h par jour choisis.</p>
<p className='text-justify mt-4  '> -Chaque point est équivaut à une heure de conversation via phone avec le couple.</p>
<p className='text-justify mt-10  '>-Pourquoi on s’est engagé?</p>
<p className='text-justify mt-4 flex  '>-Qu’est ce qui nous a attiré vers la personne?</p>
<p className='text-justify mt-10  '>-Quelles sont les qualités de notre conjoint qu’on aime vraiment?</p>
<p className='text-justify mt-4 flex  '>-Comment améliorer mon niveau de communication?</p>
   
<p className='text-justify mt-10  '>-Rappel des tempéraments, leur forces et faiblesses</p>
<p className='text-justify mt-4 flex  '>-Comment raviver notre vie sexuelle?</p>
<p className='text-justify mt-10  '>-Comment améliorer notre vie sociale?</p>
<p className='text-justify mt-4 flex  '>-Comment améliorer nos finances?</p> 
<p className='text-justify mt-4 flex  '>-Aider à se partager les tâches à la maison afin que personne ne soit déborder.</p>
  

<h1 className='text-xl flex justify-center mt-6 font-semibold'>Quizz</h1>
<p className='text-justify mt-4 flex  mt-6 font-semibold '>1-Que fais tu comme profession?</p>
 
 {
    quiz.map((data)=>(
       <section key={data.id} className='flex flex-col space-y-3 mb-6'>
<h2 className='font-semibold'>{data.question}</h2>
{
    data.responses.map((responses)=>(
        <CheckBoxed key={responses?.id}
         index={responses?.id} 
         response={responses?.response}
          status={responses?.isChecked}
          checkHandler={() => updateCheckStatus(data.id, responses?.id)}
          />
    ))
}
       </section>
    ))
 }

<h1 className='text-xl flex justify-center mt-6 font-semibold'>QUIZZ APRÈS LES COURS</h1>
{
    afterClassuiz.slice(0,4).map((question:afterClassuizProps)=>(
        <section key={question.id} className='flex flex-col space-y-2'>
        <p className='text-justify mt-4 flex  ' >{question.id}-{question.question}</p>
       <QuestionAfterClass  />
        </section>
       
    ))
}
{
    afterClassuiz.slice(4,5).map((question:afterClassuizProps)=>(
        <section key={question.id} className='flex flex-col space-y-2'>
        <p className='text-justify mt-4 flex  ' >{question.id}-{question.question}</p>
   {
    Option.map((option)=>(
        <CheckBoxed key={option?.id}
        index={option?.id} 
        response={option?.response}
         status={option?.isChecked}
         checkHandler={() => updateCheck(option?.id)}
         />
    ))
   }
        </section>
       
    ))
}
{
    afterClassuiz.slice(afterClassuiz.length-1).map((question:afterClassuizProps)=>(
        <section key={question.id} className='flex flex-col space-y-2'>
        <p className='text-justify mt-4 flex  ' >{question.id}-{question.question}</p>
       <QuestionAfterClass  />
        </section>
       
    ))
}



<section  className='flex flex-col space-y-2'>
        <p className='text-justify mt-4 flex font-semibold ' >Leave a comment</p>
       <QuestionAfterClass  />
        </section>

        <section  className='flex flex-col space-y-2'>
        <p className='text-justify mt-4 flex font-semibold ' >Partagez un témoignage</p>
       <QuestionAfterClass  />
        </section>
       
    </div>

    <TopHeader text="COUPLES EN CONFLITS "/>
    <h1 className='text-xl flex justify-center mt-6 font-semibold'>
    COMMENT REGLER LES PROBLÈMES DANS MON COUPLE ?</h1>
    <p className='text-justify mt-4 flex  '>Identifier le domaine de conflits.</p> 
    <section  className='flex flex-col space-y-3 mb-6'>
    {
    conflits.map((option)=>(
        <CheckBoxed key={option?.id}
        index={option?.id} 
        response={option?.question}
         status={option?.isChecked}
         checkHandler={() => updateCheckConflits(option?.id)}
         />
    ))
   }
        </section>

        <p className='text-justify mt-4 flex  '>Coaching en ligne ou presentiel en couple (1h 2 fois/semaine sur 2 mois) </p> 

        <p className='text-justify mt-4 flex  '>Choisissez le moyen de communication</p> 
        
        <section  className='flex flex-col space-y-3 mb-6'>
    {
    classOption.map((option)=>(
        <CheckBoxed key={option?.id}
        index={option?.id} 
        response={option?.response}
         status={option?.isChecked}
         checkHandler={() => ClassOptionCheck(option?.id)}
         />
    ))
   }
        </section>

        <h1 className='text-xl flex justify-center mt-6 font-semibold'>
        COMMENT SURMONTER LES DIFFERENTS DANS LE COUPLE?</h1>

        <p className='text-justify mt-4 flex  '>les tempéraments et leurs faiblesses</p> 
        <p className='text-justify mt-4 flex  '>La clé de l’acceptation</p> 
        <p className='text-justify mt-4 flex  '>Le renouvellement de nos pensées</p> 
</div>
    </div>
  )
}

export default Rafraichissement