import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom"
import { store } from './app/store'
import { Provider } from 'react-redux'
import { Toaster } from 'react-hot-toast';
import { ContextProvider } from './Context/Context';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <BrowserRouter>
    <ContextProvider>
    <Toaster
     position="top-center"
     reverseOrder={false}
     
     toastOptions={{duration:3000}}
    />
    <App />
    </ContextProvider>
    </BrowserRouter>
    </Provider>
  

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
