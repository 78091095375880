  import React from 'react'
    import TopHeader from '../components/TopHeading'
    
    const Couple = () => {
      return (
        <div className='w-10/12 md:w-8/12 lg:w-9/12 mx-auto mt-20 mb-16'>
            
    
        <TopHeader text="Phase préparatoire en couple."/>
    
    
        <div className='flex justify-center mt-14  w-full'>
           <h1 className='font-serif text-[18px] md:text-[24px] lg:text-[34px] font-semibold text-blue-900 text-center'>
           Section 2</h1>
            </div>
    
            <p className='text-justify mt-10 flex justify-center w-full font-semibold'>1-La relation</p>
 
            <p className='text-justify mt-14 flex justify-center w-full font-semibold'>MODULE 1</p>
            <p className='text-justify mt-8 flex justify-center w-full'>Le stage de l'amitié</p>
            
    
        {/*Here we add some videos*/}
    
    <h1>Videos..........</h1>

    <div className='w-full md:w-11/12 lg:w-9/12 mx-auto'>
    <div className='flex flex-col space-y-5  '>
    
    
    <p className='text-justify mt-4  '>Quizz</p>
      </div>
    </div>
    
            <p className='text-justify mt-14 flex justify-center w-full font-semibold'>MODULE 2</p>
            <p className='text-justify mt-8 flex justify-center w-full font-semibold'>2-Les erreurs à éviter</p>
    
            <div className='w-full md:w-11/12 lg:w-9/12 mx-auto mt-8'>
    <div className='flex flex-col space-y-5  '>
    <h1>Voir mon petit livre les érreurs des célibataires.</h1>
    
    <p className='text-justify mt-4  '>Quizz</p>
      </div>
    </div>
    
            
    
         <p className='text-justify mt-14 flex justify-center w-full font-semibold'>MODULE 3</p>
           
         <div className='flex justify-center mt-14  w-full'>
         <h1 className='font-serif text-[18px] md:text-[24px] lg:text-[34px] font-semibold text-blue-900 text-center'>
           Section 3</h1>
            </div>

            <p className='text-justify mt-14 flex justify-center w-full font-semibold'>LES FIANCAILLES</p>
            
            
 

            <div className='w-full md:w-11/12 lg:w-9/12 mx-auto'>
    <div className='flex flex-col space-y-5  '>
    
    <p className='text-justify mt-10  '>-Qu’est ce que les fiançailles?</p>
    <p className='text-justify mt-4 flex  '>-Pourquoi être fiancé?</p>
    <p className='text-justify mt-4  '>-Combien de temps doivent durer les fiançailles minimum? 2 ans minimum.</p>
    <p className='text-justify mt-4 flex  '>-Comment avoir des fiançailles saines.</p>
     
     
      </div>
    </div>


    <p className='text-justify mt-8 flex justify-center w-full'>Qui fait quoi dans le foyer? </p>
 

<div className='w-full md:w-11/12 lg:w-9/12 mx-auto'>
    <div className='flex flex-col space-y-5  '>
    
    <p className='text-justify mt-10  '>Les rôles de chacun</p>
    <p className='text-justify mt-4 flex  '>- Rôle de la femme</p>
    <p className='text-justify mt-4  '>-Rôle de l’homme</p>
    <p className='text-justify mt-4 flex  '>-Exercices à faire pour le couple séparément.</p>
     
     
      </div>
    </div>
    
    
   

  
    
    
       
    
    <p className='text-justify mt-20 flex justify-center w-full font-semibold'>MODULE 4</p>
    
    
    <p className='text-justify mt-14 flex justify-center w-full font-semibold'>Le mariage</p>
            
            
    
            <div className='w-full md:w-11/12 lg:w-9/12 mx-auto'>
    <div className='flex flex-col space-y-5  '>
    
    <p className='text-justify mt-10  '>-Définition biblique du mariage.</p>
    <p className='text-justify mt-4 flex  '>-Différents types de mariage.</p>
    <p className='text-justify mt-10  '>-Le mariage selon Dieu.</p>

      </div>
    </div>
    
    
    
    
    
    
    
    
     
    
    
    <p className='text-justify mt-14 flex justify-center w-full font-semibold'>MODULE 5</p>
            <p className='text-justify mt-8 flex justify-center w-full'>(UNIQUEMENT POUR CEUX SUR LE POINT DE SE MARIER DANS MOINS D’UN MOIS) </p>
            <p className='text-justify mt-14 flex justify-center w-full font-semibold'>LA LUNE DE MIEL</p>
    
            <div className='w-full md:w-11/12 lg:w-9/12 mx-auto'>
    <div className='flex flex-col space-y-5  '>
    
    <p className='text-justify mt-10  '>-Pourquoi la lune de miel?</p>
    <p className='text-justify mt-4 flex  '>-Comment se préparer pour la nuit du mariage?</p>
    <p className='text-justify mt-4 flex  '>-Quoi éviter?</p>
    <p className='text-justify mt-4  '>-Quoi faire?</p>
      </div>
    </div>

    <p className='text-justify mt-14 flex justify-center w-full font-semibold'>PREPARATION PHYSIQUE A LA CEREMONIE DE MARIAGE</p>
    

    <p className='text-blue-800 text-center'>Check list comes here!!!</p>
    <p className='text-justify mt-8 flex justify-center w-full'>FIN DES COURS DE PREPARATION AU MARIAGE</p>
          
    
    
    
    
        </div>
      )
    }
    
    export default Couple