import Signup from "./Signup"
import {FcCancel} from "../Icons"
import { useDispatch } from 'react-redux'
import { useContextApi } from '../Context/Context'
import { useNavigate } from 'react-router-dom';



type Prop ={
    show:()=>void
    IsFormVisible:boolean
}

type formProps ={
  email:string 
  phone:number  | string
  password:string 
  confirmpassword?:string | undefined
}



 
const LoginForm = () => {


  const {isFormVisible, setIsFormVisible}  =useContextApi()
  const dispatch = useDispatch()
  const navigate = useNavigate()

 

  const ShowPopup  = () =>{
    setIsFormVisible(!isFormVisible)
    navigate("/")

  }

 


  return (
    <div className={`${!isFormVisible && "hidden"}  fixed left-0 top-0 right-0 bottom-0
     bg-black z-50 opacity-95`}>
       
          <div className=' flex  justify-center  h-screen items-center my-auto  w-9/12 sm:w-7/12
           md:w-6/12 lg:w-5/12 mx-auto'>
          <div className='bg-white rounded-md h-fit p-4 z-50 w-full '>

            {/*Cancel button*/}
          <section className='relative w-full flex justify-end  p-3'>
          <div className='absolute right-1 top-1'>
        <FcCancel onClick={ShowPopup} className="cursor-pointer text-red-500 h-6 w-6"/>
      </div>
     </section>

       <Signup/>
     
   


          </div>
          
          </div>
       
    </div>
  )
}

export default LoginForm

//withForm(LoginForm)