import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface LogState {
  error: string,

}

const initialState: LogState = {
    error: "",
    
}

export const ErrorSlice = createSlice({
  name: 'Error',
  initialState,
  reducers: {
    UpdateError: (state,action:PayloadAction<string>) => {
 
      state.error = action.payload
    },

 
  },
})

// Action creators are generated for each case reducer function
export const { UpdateError} = ErrorSlice.actions

export default ErrorSlice.reducer