

const TopHeader = ({text}:{text:string}) => {
  return (
    <div className='flex justify-center py-3 w-10/12 md:w-8/12 lg:w-9/12 mx-auto '>
       <h1 className=' text-blue-900 text-center  font-regular text-[18px] md:text-[22px] lg:text-[28px]  text-center'>{text}</h1>
        </div>
  )
}

export default TopHeader