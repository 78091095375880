import React , {useEffect, useState,useCallback} from 'react'
import TopHeader from '../components/TopHeading'
import {auth} from "../Firebase"
import { useNavigate } from 'react-router-dom';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import {  FirebaseError } from "firebase/app";
import { toast } from 'react-hot-toast';




const VerifyEmail = () => {

    const isBrowser = () => typeof window !== "undefined";
    const appHome = "/";
    const navigate = useNavigate();
    const [Error,setError] = useState("")
    const [loading,setloading] = useState(false)
    
  
    const redirectToAppHome =  useCallback(() => {
      navigate(appHome);
    },[]);

    const getStorageEmail =  useCallback(() => {
      if (!isBrowser()) {
        return;
      }
     return window.localStorage.getItem("emailForSignIn");
   
    },[]);
 
    const getOriginHref =  useCallback(() => {
      if (!isBrowser()) {
        return;
      }
    
      return window.location.href;
    },[]);
      
      function clearEmailFromStorage() {
        window.localStorage.removeItem("emailForSignIn");
      }
      
    

      useEffect(() => {
        // let's prevent duplicate calls (which should only happen in dev mode)
        // if (requestExecutedRef.current) {
        //   return;
        // }
        setError("")
        setloading(true)
        const href = getOriginHref();
    
        // do not run on the server
        if (!href) {
            setError('Sorry! Something went wrong.');
    
          return;
        }
    
        // let's verify the auth method is email link
        if (!isSignInWithEmailLink(auth, href)) {
            setError('Sorry! Something went wrong.');
    
          return;
        }
    
        const email = getStorageEmail();
    
        // let's get email used to get the link
        if (!email) {
          setError('Email not available');
    
          return;
        }
    
        void (async () => {
       //   requestExecutedRef.current = true;
    
       
            // sign in with link, and retrieve the ID Token
         signInWithEmailLink(auth, email, href)
         .then((res)=>{
          if(res){
                // let's clear the email from the storage
                clearEmailFromStorage();
                setloading(false)
                // redirect user to the home page
                redirectToAppHome();
                setError("")
                toast.success("Bienvenu à l'École de mariage divin")
          }
         }).catch((error)=>{
          if (error instanceof FirebaseError){
            setError(error.code);
          } else {
            setError('Sorry! Something went wrong.');
          }
         })
    
         
        })();
      }, [
         getOriginHref,
        getStorageEmail,
        redirectToAppHome,
        setError,
      
      ]);

  return (
    <div className='w-10/12 md:w-8/12 lg:w-9/12 mx-auto mt-20 mb-16'> 
    <TopHeader text="Email verification"/>
    <>
     {loading && <h1 className='flex justify-center mt-8 text-center text-xl'> Signing in...</h1>}

    {Error &&   <div className={'flex  justify-center mt-8'}>
        <h1 className='text-xl text-center'>{Error}</h1>
        </div>}
    </>
    </div>
  )
}

export default VerifyEmail