// Import the functions you need from the SDKs you need
import { initializeApp, FirebaseError } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth  } from "firebase/auth";
import { getStorage  } from "firebase/storage";
 

const firebaseConfig = {
  apiKey: "AIzaSyCkbnGS9B14nCpZ5XRekwlUwNtP1uGv2EE",
  authDomain: "marriageschool-a606d.firebaseapp.com",
  projectId: "marriageschool-a606d",
  storageBucket: "marriageschool-a606d.appspot.com",
  messagingSenderId: "17473329801",
  appId: "1:17473329801:web:09bb9a579162ed2bb4c458",
  measurementId: "G-KJK9R8HNBZ"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
const analytics = getAnalytics(app);
export const storage = getStorage(app);