import React , {useEffect, useState, lazy, Suspense} from 'react';
import Header from './components/Header';
import {Route, Routes} from "react-router-dom"
import Home from './Pages/Home';
import Propos from './Pages/Propos';
import Couple from './Pages/Couple';
import Sidemenu from './components/Sidemenu';
import Partenaires from './Pages/Partenaires';
import Temoignages from './Pages/Temoignages';
import Events from './Pages/Events';
import News from './Pages/News';
import Podcast from './Pages/Podcast';
import Donation from './Pages/Donation';
import LoginForm from './components/Loginform';
import {onAuthStateChanged } from "firebase/auth";
import { auth } from './Firebase';
import { useDispatch } from 'react-redux';
import VerifyEmail from './Pages/VerifyEmail';
import Inscription from './Pages/Inscription';
import {  CreateUser } from './features/UserSlice';
import Loading from './components/Loading';
import Rafraichissement from './Pages/Rafraichissement';
import { useContextApi } from './Context/Context';
const  Preparation = lazy(()=>import("./Pages/Preparation"))
const  Boutique = lazy(()=>import("./Pages/Boutique"))
const  Mariage = lazy(()=>import("./Pages/Mariage"))
 

function App() {

  const {isAppLoading, setAppLoading} = useContextApi()
  const dispatch = useDispatch()


  useEffect(() => {
   
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      
      if(!currentuser){
        setAppLoading(false)
        return
      }
      
      
      dispatch(CreateUser(currentuser.uid))
      setAppLoading(false)
 
    });

    return () => {
      unsubscribe();
    };
  },[]);


if(isAppLoading)
return <Loading/>

 
  return (
    <main className='relative h-full w-full'>
      <Header/>
   

   <nav>
   <Suspense fallback={<Loading/>}>

     <Routes>
          <Route  path='/'  element={<Home/>}/>
          <Route path='/propos' element={<Propos/>}/>
          <Route path='/mariage' element={<Mariage/>}/>
          <Route path='/Cours' element={<Preparation/>}/>
          <Route path='/individuel' element={<Preparation/>}/>
          <Route path='/couple' element={<Couple/>}/>
          <Route path='/Boutique' element={<Boutique/>}/>
          <Route path='/rafraîchissement' element={<Rafraichissement/>}/>
          <Route path='/auth/link' element={<VerifyEmail/>}/>
          <Route path='/Podcast' element={<Podcast/>}/>
          <Route path='/News' element={<News/>}/>
          <Route path='/Événements' element={<Events/>}/>
          <Route path='/Témoignages' element={<Temoignages/>}/>
          <Route path='/Partenaires' element={<Partenaires/>}/>
          <Route path='/don' element={<Donation/>}/>
          <Route path='/Inscription' element={<Inscription/>}/>
     </Routes>
     </Suspense>
   </nav>


     <aside>
     <Sidemenu/>
     </aside>

  
     <section>
    <LoginForm />
    </section>


 
    
    </main>
  );
}

export default App;
