import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface LogState {
  UserID: string,
}

const initialState: LogState = {
    UserID: "",
}

export const userSlice = createSlice({
  name: 'userslice',
  initialState,
  reducers: {
    CreateUser: (state,action:PayloadAction<string>) => {
 
      state.UserID = action.payload
    },
 
 
  },
})

// Action creators are generated for each case reducer function
export const { CreateUser} = userSlice.actions

export default userSlice.reducer