import { useContext, createContext, useState, useEffect } from "react";

type initialType ={
    setIsFormVisible: React.Dispatch<React.SetStateAction<boolean>>
    isFormVisible: boolean
    setGetSavedPassword: React.Dispatch<React.SetStateAction<string>>
    password: string
    isAppLoading: boolean
    setAppLoading: React.Dispatch<React.SetStateAction<boolean>>
}

type contextChildren ={
children: React.ReactNode
}

export const ContextApi = createContext({} as initialType)


export const ContextProvider = ({children}:contextChildren)=>{


    const [isFormVisible, setIsFormVisible] = useState(false)
    const [password, setGetSavedPassword] = useState("")
    const [isAppLoading, setAppLoading] = useState(true)
 

    useEffect(()=>{

        if(typeof window !== 'undefined'){
        const savedData = localStorage.getItem('userID')
         if(savedData){
            setGetSavedPassword(savedData)
            setAppLoading(false)
            setIsFormVisible(false)
           
            
            
         }
        else{
            setAppLoading(false)
            
         }

        }
      }, [password])

    return (
        <ContextApi.Provider value={{setIsFormVisible, isAppLoading,
         isFormVisible, password, setGetSavedPassword, setAppLoading}}>
            {children}
        </ContextApi.Provider>
    )
}


export const useContextApi = ()=>useContext(ContextApi)