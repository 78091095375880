import React, { useEffect, useRef, useState } from 'react'
import { HiOutlineMenu,MdCancel, MdKeyboardArrowDown, MdKeyboardArrowUp } from '../Icons'
import TopHeader from './TopHeader'
import {menu} from "../Data"
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { showMenu} from "../features/Menuslice"
import { useContextApi } from '../Context/Context'
 



const Header = () => {



 

   const {password, setIsFormVisible,setGetSavedPassword} = useContextApi()
    const isMenuOpen = useSelector((state: RootState) => state.menu.IsVisible)
    const dispatch = useDispatch()
    const [SelectedItem,setSelectedItem] = useState<number | null >(null)
    const [SelectedSubmenu,setSelectedSubmenu] = useState(0)
    const [ShowSubmenu,setShowSubmenu] = useState(true)
    const RefMenu = useRef<HTMLDivElement | null>(null);
    const refs =useRef<HTMLDivElement[]>([]);
    const [IscourseVisible, setIscourseVisible] = useState(false)
    const refShowmen =useRef<HTMLDivElement>(null);
    const refSHide =useRef<HTMLDivElement>(null);
    
    const urlToCheck = ["individuel", "couple", "rafraîchissement", "Dieu", "rafra%C3%AEchissement"]

    useEffect(()=>{
    if(typeof window !== undefined ){
     
       if(urlToCheck.includes(window.location.pathname.split("/")[1].trim())){
       
         const savedPassword = localStorage.getItem('userID')
          if(savedPassword==="" || savedPassword == null){
            setIsFormVisible(true)
          }else{
            setGetSavedPassword(savedPassword)
          }
       }
       
    }
    },[window.location.pathname])

   

//Here we create a function for selecting items with submen
const SelectSub = (ID:number) =>{
    if(ID===0)
    return
    setSelectedSubmenu(ID)
    setSelectedItem(null)
   setIscourseVisible(false)
}
 
 

const Selecteditem = (ID:number) =>{
    setSelectedItem(ID)
  
  //  setShowSubmenu(false)
if(SelectedItem!== 4){
    setSelectedSubmenu(0)
}
}

useEffect(()=>{
 let handle = (e:MouseEvent)=>{
    if(!RefMenu.current?.contains(e.target as HTMLElement)){
        setShowSubmenu(false)
        setIscourseVisible(false)
   
    } 
 }
 document.addEventListener('click',handle)
 return ()=>{
    document.removeEventListener('click',handle)
 }
})

//keep submenu visible 

useEffect(()=>{
 let handle = (e:MouseEvent)=>{
    if(RefMenu.current?.contains(e.target as HTMLElement)){
        setShowSubmenu(true)
   } 
 }
 document.addEventListener('click',handle)
 return ()=>{
    document.removeEventListener('click',handle)
 }
})


//Show menu
   useEffect(()=>{
    let handle = (e:MouseEvent)=>{
       if(refShowmen.current?.contains(e.target as HTMLElement)){
           dispatch(showMenu(true))
         
      } 
    }
    document.addEventListener('click',handle)
    return ()=>{
       document.removeEventListener('click',handle)
    }
   },[isMenuOpen])

  

//Hide side menu
   useEffect(()=>{
    let handle = (e:MouseEvent)=>{
       if(refSHide.current?.contains(e.target as HTMLElement)){
           dispatch(showMenu(false))
      } 
    }
    document.addEventListener('click',handle)
    return ()=>{
       document.removeEventListener('click',handle)
    }
   },[isMenuOpen])
    
  return (
    <header className='w-full top-0 sticky z-40'>
        <TopHeader/>
        <nav className='shadow-lg w-full border-t border-b border-gray-100 py-4 bg-white '>
            <nav className='px-4 flex items-center justify-between '>

            {/*Main part*/}

    <div role='navigation' ref={RefMenu}  className='flex items-center space-x-3 flex-1 justify-center hidden lg:flex'>

    {
    menu.slice(0,2).map((item)=>(
    <ul key={item.id} onClick={()=>Selecteditem(item.id)}  
    className=" ">
    <li className='flex items-center'>
    <Link to={`${item.menuText.split(" ")[item.menuText.split(" ").length-1]}`}>
    <h1 className={` ${item.id === SelectedItem ? " text-blue-900 font-semibold hover:text-blue-900" : "text-gray-400"} 
    text-[16px]  `}>{item.menuText}</h1>  
    </Link>
    </li>
    </ul>
    ))
    }


    <div role='group' className='relative'>
    <nav className=' flex items-center space-x-1'>
    <h1 onClick={()=>setIscourseVisible(!IscourseVisible)}>Cours</h1>
    <>
        {
           IscourseVisible ?  <MdKeyboardArrowUp
           className={`${IscourseVisible ? "text-blue-900" : "text-gray-400"}  Icon`}/>
         : (
            <MdKeyboardArrowDown
            className={`${IscourseVisible ? "text-blue-900" : "text-gray-400"}  Icon`}/>
          
         )
        }
     
    </>
    </nav>

   {
    IscourseVisible &&

         <nav 
            className={`${ IscourseVisible  ? "absolute  top-12 left-0 flex flex-col  w-64 px-4 z-30 space-y-2 text-black bg-white border border-gray-300  rounded-md py-4 shadow-lg " :" hidden"} `}>
               {
                 menu.map((subItem)=>{
                  return  subItem?.submenu?.map((sUb)=>(
                    <div role='group'  ref={(el:HTMLDivElement)=>(refs.current[sUb.id]=el)}   
                    key={sUb?.id} className={`flex items-center space-x-3`} 
                    onClick={()=>SelectSub(sUb.id)}>
                       {
                     sUb.icon !== undefined && <sUb.icon className={`Icon ${sUb?.color}`}/>
                       }
                   <Link to={`${ sUb?.sub.split(" ")[sUb?.sub.split(" ").length-1]}`}>
                   <h1  className={`${SelectedSubmenu === subItem.id  && sUb.id === SelectedItem ? " font-semibold text-blue-900" : "text-gray-400"}`}>{sUb?.sub}</h1>
                    </Link>
                   </div>
                    ))
                 })
                   }
                   
         </nav>
    }
    </div>
   
    {
    menu.slice(3,menu.length).map((item)=>(
    <ul key={item.id} onClick={()=>Selecteditem(item.id)}  
    className=" ">
    <li className='flex items-center'>
    <Link to={`${item.menuText.split(" ")[item.menuText.split(" ").length-1]}`}>
    <h1 className={` ${item.id === SelectedItem ? " text-blue-900 font-semibold" : "text-gray-400"} 
    text-[16px]  `}>{item.menuText}</h1>  
    </Link>
     </li>
   </ul>
    ))
    }
    </div>
 
            {/*Menu buttons*/}
          
         {
            !isMenuOpen ? (
                <div role='button' ref={refShowmen} className='flex items-center space-x-2 bg-blue-900 lg:hidden p-1 cursor-pointer'>
                <HiOutlineMenu className='text-white h-6 w-6'/>
                <h1   className='text-white'>Menu</h1>
            </div>
            )
            :
            (
               <div role='button'  ref={refSHide}  className='bg-blue-900 lg:hidden p-1 cursor-pointer rounded-full flex items-center justify-center'>
                <MdCancel className='text-white h-6 w-6'/>
                </div>
            )
         }
           


             </nav>
        </nav>
    </header>
  )
}

export default Header