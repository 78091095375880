import  { useEffect, useRef, useState } from 'react'
import {   MdKeyboardArrowDown, MdKeyboardArrowUp } from '../Icons'
import {menu} from "../Data"
import { Link ,useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../app/store'
import {showMenu} from "../features/Menuslice"




const Sidemenu = () => {

    const ShowMenu = useSelector((state: RootState) => state.menu.IsVisible)
    const dispatch = useDispatch()
    const [SelectedItem,setSelectedItem] = useState<number | null >(null)
    const [SelectedSubmenu,setSelectedSubmenu] = useState(0)
    const [,setShowSubmenu] = useState(true)
    const RefMenu = useRef<HTMLDivElement | null>(null);
    const refs =useRef<HTMLUListElement[]>([]);
    const [,setgetPath] = useState("")
    const [, setPreviousPath] = useState<string[]>([]);
    const Location = useLocation()
    const [IscourseVisible, setIscourseVisible] = useState(false)

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      });
 

      useEffect(() => {
    

        if(!ShowMenu && windowSize[0]>=1025)
        return 

        if(windowSize[0]>=1025){
            dispatch(showMenu(false))
        }
      
      },[windowSize[0]]);
       


useEffect(()=>{
    setgetPath(Location.pathname)
 if(Location.pathname === "/"){
 setSelectedItem(null)
}
 
 
setPreviousPath(prev=>{
    return [
        ...prev, Location.pathname
    ]
      
})

},[ Location.pathname])

   

{/*Here we create a function for selecting items with submen*/}
const SelectSub = (ID:number) =>{
    setSelectedSubmenu(ID)
    setSelectedItem(null)
    setIscourseVisible(false)
   
}
 
const ShowCours = ()=>{
  setIscourseVisible(!IscourseVisible)
  setSelectedItem(null)
}
 

const Selecteditem = (ID:number) =>{
    setSelectedItem(ID)
    setIscourseVisible(false)
    dispatch(showMenu(false))
  //  setShowSubmenu(false)
if(SelectedItem!== 4){
    setSelectedSubmenu(0)
}
}

useEffect(()=>{
 let handle = (e:MouseEvent)=>{
    if(!RefMenu.current?.contains(e.target as HTMLElement)){
        dispatch(showMenu(false))
   
    } 
 }
 document.addEventListener('click',handle)
 return ()=>{
    document.removeEventListener('click',handle)
 }
})

useEffect(()=>{
 let handle = (e:MouseEvent)=>{
    if(RefMenu.current?.contains(e.target as HTMLElement)){
        setShowSubmenu(true)
   } 
 }
 document.addEventListener('click',handle)
 return ()=>{
    document.removeEventListener('click',handle)
 }
})

useEffect(()=>{

    let handle = (e:MouseEvent)=>{
        if(SelectedSubmenu=== 0)
           return 
  if(refs.current[SelectedSubmenu].contains(e.target as HTMLHeadingElement)){
           setShowSubmenu(false)
           console.log(refs.current[SelectedSubmenu])
  
       } 
    }
    document.addEventListener('click',handle)
    return ()=>{
       document.removeEventListener('click',handle)
    }
   })
 

    
  return (
    <nav  className={`${!ShowMenu && "hidden"}     
    fixed right-0 top-0
     z-50`}>
      <div className='overflow-y-auto h-screen'>
      <nav ref={RefMenu}
     className='relative flex flex-col p-6 items-center 
     justify-center bg-black opacity-90  space-y-3  rounded-b-md
     lg:hidden mb-6 '>

    {
    menu.slice(0,2).map((item)=>(
    <ul key={item.id} onClick={()=>Selecteditem(item.id)} 
     className="flex flex-col justify-center  relative">
    <li className='flex items-center  '>
       
     
            <Link to={`${item.menuText.split(" ")[item.menuText.split(" ").length-1]}`}>
                <h1 className={` ${item.id === SelectedItem ? " text-blue-900 font-semibold" : "text-gray-500 hover:text-gray-300"} 
            text-[16px]  `}>{item.menuText}</h1>  
                </Link>
     
  
    </li>

{/*Sub menu goes here*/}
 

 </ul>
    ))
    }

<div role='navigation' className='relative flex flex-col space-y-2 items-center justify-center'>
   <nav className=' flex items-center space-x-1' onClick={()=>ShowCours()}>
    <h1
    className={` ${IscourseVisible ? " text-blue-900 font-semibold" : "text-gray-500 hover:text-gray-300"} 
    text-[16px]  `}
    >Cours</h1>
    <>
        {
           IscourseVisible ?  <MdKeyboardArrowUp
           className={`${IscourseVisible ? "text-blue-900" : "text-gray-400"}  Icon`}/>
         : (
            <MdKeyboardArrowDown
            className={`${IscourseVisible ? "text-blue-900" : "text-gray-400"}  Icon`}/>
          
         )
        }
     
    </>
   </nav>
   {
    IscourseVisible &&

  <nav 
  className={`${ IscourseVisible  ? "flex flex-col space-y-3  w-fit px-4 z-30 space-y-2 text-black " :" hidden"} `}>
               {
                 menu.map((subItem)=>{
                  return  subItem?.submenu?.map((Sub)=>(
                    <ul  ref={(el:HTMLUListElement)=>(refs.current[Sub.id]=el)}   
                    key={Sub?.id} className={`flex items-center space-x-3`} 
                    onClick={()=>SelectSub(Sub.id)}>
                    
                   <Link to={`${ Sub?.sub.split(" ")[Sub?.sub.split(" ").length-1]}`}>
                   <li  className={`${SelectedSubmenu === subItem.id  && Sub.id === SelectedItem ? 
                    " font-semibold text-blue-900 " : "text-gray-400"} text-[14px]`}>{Sub?.sub}
                    </li>
                    </Link>
                   </ul>
                    ))
                 })
                   }
                   
           </nav>
    
    
   
   }
</div>

{
    menu.slice(3,menu.length).map((item)=>(
    <div key={item.id} onClick={()=>Selecteditem(item.id)}  
    className=" ">
    <div className='flex items-center'>
       
      
            <Link to={`${item.menuText.split(" ")[item.menuText.split(" ").length-1]}`}>
            <h1 className={` ${item.id === SelectedItem ? " text-blue-900 font-semibold" : "text-gray-400"} 
        text-[16px]  `}>{item.menuText}</h1>  
            </Link>
      
    </div>
  </div>
    ))
    }
          

             {/* {
              IsuserSignedIn ? <section className='w-full px-4'>
                <h1 className='text-center text-white'>Connecté...</h1>
              </section>
              :
              (
              <ConnectBtn color="text-white"/>
              )
             } */}
             
    </nav>
 </div>
 
    
    </nav>
  )
}

export default Sidemenu