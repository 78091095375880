import React, { FormEvent, useEffect, useState } from 'react'
import TopHeader from '../components/TopHeading'
import { addDoc, collection} from "firebase/firestore"; 
import { db } from '../Firebase';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';


  
 type formProps ={
    firstname:string 
    lastname: string 
    address:string 
    phone:string
  }

type InputType = 'text'  | 'number';


type fieldprops  ={
Type:InputType
Handle: (e: React.ChangeEvent<HTMLInputElement>) => void
name:string 
value: string 
nameID:string

}
const FormField =  ({name, Type, Handle, value, nameID}:fieldprops )=>(

    <label className=''>
    {name}:
    <input
      id='Name'
      name={nameID}
      type={Type}
      value={value}
      onChange={Handle}
      className='w-full outline-none px-2 py-1 border border-gray-300 rounded-md'
    />
  </label>
)
const Inscription = () => {


  const userID = useSelector((state: RootState) => state.userslice.UserID)
 
    const [FormData, setFormData] = useState<formProps>({
        address:"",
        firstname:"",
        lastname:"",
        phone:""
    })

 const [IsButtonDisabled, setIsButtonDisabled] = useState(true)
 const [isLoading, setisLoading] = useState(false)

    const HandleField = (e: React.ChangeEvent<HTMLInputElement>)=>{
        setFormData((prev)=>{
            return {
                ...prev,
         [e.target.name]:e.target.value
            }
        })
    }
 
  useEffect(()=>{
    if(FormData.firstname && FormData.phone  && FormData.lastname  &&  FormData.address )
    setIsButtonDisabled(false)
    else 
    setIsButtonDisabled(true)
  },[FormData])
        
    const checkout =   async (e:FormEvent)=>{
        e.preventDefault()

        if(!FormData.lastname)
        return alert("Désolé! le nom est nécessaire.")
        if(!FormData.firstname)
        return alert("Désolé! le prénom est nécessaire.")
        if(!FormData.address)
        return alert("Désolé! l'adresse' est nécessaire.")
        if(!FormData.phone)
        return alert("Désolé! le numéro de téléphone est nécessaire.")


      
        setisLoading(true)
 

  addDoc(collection(db, "users"), {
  lastname: FormData.lastname,
  firstname: FormData.firstname,
  address: FormData.address,
  phone:FormData.phone,
  UserID: userID
})
.then((res)=>{
 
  setFormData({
    address:"",
    firstname:"",
    lastname:"",
    phone:""
})
  setisLoading(false)
}).catch((error)=>{
  setisLoading(false)
  console.log(error)
})

 

      
    }
  return (
    <section className='w-10/12 md:w-8/12 lg:w-9/12 mx-auto mt-20 mb-16'> 
    <TopHeader text="Inscription"/>
    <form className='w-full lg:w-7/12 mx-auto  mt-8' onSubmit={checkout}>
    <FormField name='Nom' Type='text' nameID='lastname' value={FormData.lastname} Handle={HandleField}/>
    <FormField name='Prénom' Type='text'  nameID='firstname' value={FormData.firstname} Handle={HandleField}/>
    <FormField name='Adresse' Type='text' nameID='address' value={FormData.address} Handle={HandleField}/>
    <FormField name='Téléphone' Type='number' nameID='phone' value={FormData.phone}  Handle={HandleField}/>
{
  isLoading ? <h1 className='flex justify-center text-center mt-6'>⏳⌛️ Loading...</h1>
  :
  (
<div role='button' className={`${IsButtonDisabled? "bg-blue-200":"bg-blue-700"} mt-4  w-fit mx-auto`}>
<button type='submit'
disabled={IsButtonDisabled} 
    className='w-fit text-white  px-2 py-1 text-center'>Submit</button>
</div>
  )
}

    </form>
    </section>
  )
}

export default Inscription