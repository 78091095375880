import { BiBible , FaUserFriends, AiFillRead, FaUserTie} from "./Icons";
import { BooksProps, menuProps } from "./TypeData";


export const menu :menuProps[] = [{
    id:0,
    menuText:"À propos"

},
{
    id:1,
    menuText:"Pourquoi les cours de mariage"

}
,
{
    id:2,
    menuText:"Cours",
    submenu:[{
        id:1,
        sub:"Les cours de préparations individuel",
        icon:FaUserTie,
        color:'text-blue-800'
        
    },
    {
        id:2,
        sub:"Les cours de préparations en couple",
        icon:FaUserFriends,
        color:'text-blue-800'
    },
    {
        id:3,
        sub:"Les cours de rafraîchissement",
        icon:AiFillRead,
        color:'text-green-600'

    },
    {
        id:4,
        sub:"Les cours pour connaître Dieu",
        icon:BiBible,
        color:'text-red-600'
    }
]

},
{
    id:3,
    menuText:"Boutique"

},
{
    id:4,
    menuText:"Podcast"

},
{
    id:5,
    menuText:"News"

},
{
    id:6,
    menuText:"Événements"

},
{
    id:7,
    menuText:"Témoignages"

},
{
    id:8,
    menuText:"Partenaires"

}


]

export const Books : BooksProps [] = [
    {
    id:1,
    title:"Pourquoi les gens font le mauvais choix en amour?",
    img:"/book1.jpg"
    
    },
    {
    id:2,
    title:"Comment plaire à son conjoint?",
    img:"/book2.jpg"
    
    },
    {
    id:3,
    title:"Pourquoi attendre qu'il soit tard avant de prier?",
    img:"/book3.jpg"
    
    },
    {
    id:4,
    title:"Les Fantômes du passé",
    img:"/book4.jpg"
    
    },
    {
    id:5,
    title:"Les oeuvres qui parlent",
    img:"/book5.jpg"
    
    },
    {
    id:6,
    title:"Les illusions des celibataires",
    img:"/book6.jpg"
    
    }
    ]