 
import TopHeader from '../components/TopHeading'


type mission ={
  id:number
  description: string
}
const Propos = () => {


   

const But = "L’école internationale du mariage divin est une école en ligne qui prépare les individus, couples au mariage, les assiste dans la planification de leur mariage selon leur budget et les aide à régler leurs conflits dans le mariage."
const Mission = ""

const missions : mission[] = [
  {
    id:1,
    description:"Restaurer l’image du mariage et de la cellule familiale en posant des fondements solides basés sur la parole de Dieu. "
  },
  {
    id:2,
    description:"Préparer et équiper les jeunes filles à être des femmes domestiques."
  },
  {
    id:3,
    description:"Conscientiser les jeunes hommes sur leur responsabilité et impact sur la famille et la génération à venir."
  }
]
 


return (
    <div className='w-10/12 md:w-8/12 lg:w-9/12 mx-auto mt-20 mb-16'>
        
        

        <TopHeader text="À propos"/>

        <div className=' flex w-10/12 md:w-9/12 lg:w-10/12 mx-auto my-10 h-40 md:h-64 lg:h-72'>
        <div className='h-screen flex items-center justify-center bg-no-repeat bg-center bg-cover ' style={{backgroundImage: `url(/goal.PNG)`,height:'100%', width:'100%', objectFit:'contain', position:'relative'}}>
        {/* Your content here */}
     <div className='bg-black absolute inset-0 opacity-60 flex items-center justify-center '>
   
   

      </div>
      </div>
      </div>


        <div className='flex justify-center mt-14  w-full'>
       <h1 className='font-adelia text-[18px] md:text-[24px] lg:text-[34px] font-semibold text-blue-900 text-center'>
        But</h1>
        </div>

        <p className='text-justify mt-4 flex justify-center w-full'>{But}</p>

        <div className='flex justify-center mt-14  w-full'>
       <h1 className='font-adelia text-[18px] md:text-[24px] lg:text-[34px] font-semibold text-red-900 text-center'>
       Mission</h1>
        </div>

        <ul className='list-decimal'>
        {
          missions.map((mission)=>{
            return <li 
            className='text-justify mt-4 flex items-start  w-full' 
            key={mission.id}><span className='font-bold pr-4'>-</span> {mission.description}</li>
          })
        }
        </ul>

        

        <div className='flex justify-center mt-14  w-full'>
       <h1 className='font-adelia text-[18px] md:text-[24px] lg:text-[34px] font-semibold text-green-900 text-center'>
       Vision</h1>
        </div>

        <p className='text-justify mt-4 flex justify-center w-full'>Former 1000 couples à la préparation au mariage par an à travers le monde en les équipant des outils spirituels et intellectuels afin de vivre un mariage glorieux.</p>

    </div>
  )
}

export default Propos